.home-framework {
  height: 50vh;
  width: 100%;
  background: #26395e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  /* border-radius: 10px; */
    margin-top: 5vh;
    padding-bottom: 5vh;
    padding-top: 5vh;
    /* box-shadow: 20px 10px 50px #d9d9d9, 0px 0px 20px #ffffff; */
}

.home-framework-left-container {
  height: 100%;
  width: 100%;
  /* background: rgb(151, 52, 52); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-framework-left-warp {
  /* change it to fit-content */
  height: 100%;
  width: 80%;
  /* background: rgb(160, 160, 160); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 10px; */
}

.home-framework-title {
  width: 100%;
  height: 20%;
  /* background: rgb(14, 123, 185); */
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #fff;
  text-align: center;
}

.home-framework-content {
  width: 80%;
  height: 50%;
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  /* background: rgb(14, 185, 176); */
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 400;
  font-size: var(--font-size-3);
  color: #fff;
}

/* .home-framework-right-container {
  height: 100%;
  width: 30%;
}

.home-framework-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
} */

@media only screen and (max-device-width: 760px) {
  .home-framework {
    height: fit-content;
    flex-direction: column;
  }
  .home-framework-left-container {
    width: 100%;
  }
  .home-framework-left-warp {
    width: 100%;
    gap: 25px;
    align-items: center;
  }
.home-framework-title {
    text-align: center;
    width: fit-content;
    /* background: #000; */
  }
  .home-framework-content {
    align-items: center;
  }

  .home-framework-right-container {
    width: 100%;
  }
}
